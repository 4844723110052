@import url(https://unpkg.com/tachyons@4.10.0/css/tachyons.min.css);
html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.fab-container {
  margin: 0px !important;
  right: 3vw !important;
  z-index: 9999 !important;
}

/*** OVERALL FIXED INTERNAL BODY WIDTH MAX-SIZING FOR IDEAL DISPLAY RESOLUTION DESPITE SCREEN SIZES ***/
.max-body-sizing {
  max-width: 1024px !important;
}
/*** ADDITIONAL FONT SIZE SETTINGS ***/
.f8 {
  font-size: .6rem;
}
.f9 {
  font-size: .5rem;
}
.ant-radio-button-wrapper {
  padding: 0 !important;
}
#jobAdPackageRadioGroup .ant-radio-button-wrapper-checked:first-child {
  border-width: 3px !important;
}
.filterBtn .ant-radio-button-wrapper {
  padding: 0px 7px !important;
}
.creatExtraNewPostBtn {
  color: #000000 !important;
}


/*** MAIN PAGE SEARCH BUTTON TOOLTIP CSS DISPLAY SETTINGS  ***/
.search-trigger-icon-button {
  background: none !important;
  cursor: pointer !important;
  padding: 0 !important;
  border: none !important;
}
.rc-tooltip {
  opacity: 1 !important;
}
.rc-tooltip-inner {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px !important;
}


.aspect-ratio--object {
  z-index: -100 !important;
}
.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: #357EDD !important;
}


/*** PAGES SEARCH BAR CSS DISPLAY SETTINGS  ***/
.page-search-bar input {
  background: rgba(255, 255, 255, 0.2) !important;
  border: none !important;
  transition: translateX 6s ease-in;
  text-align: right !important;
  width: 100% !important;
  height: 32px !important;
  -webkit-font-feature-settings: "tnum" !important;
          font-feature-settings: "tnum" !important;
  font-variant: tabular-nums !important;
  list-style: none !important;
  display: inline-block !important;
  padding: 4px 11px !important;
  font-size: 14px !important;
  line-height: normal !important;
  border-radius: 4px !important;
  padding-right: 60px !important;
  color: rgba(255, 255, 255, 0.9) !important;
}

.page-search-bar input:hover {
  text-align: left !important;
}
.page-search-bar input:hover::-webkit-input-placeholder  {
  text-align: left !important;
}
.page-search-bar input:hover::-webkit-input-placeholder:-moz-placeholder::-moz-placeholder {
  -webkit-transform: translateX(-70%);
  transform: translateX(-70%);
  opacity: 0;
  color: rgba(0, 0, 0, 0.65) !important;
  background: rgba(255, 255, 255, 0.9) !important;
}
.page-search-bar input:hover::-webkit-input-placeholder:-ms-input-placeholder {
  -webkit-transform: translateX(-70%);
  transform: translateX(-70%);
  opacity: 0;
  color: rgba(0, 0, 0, 0.65) !important;
  background: rgba(255, 255, 255, 0.9) !important;
}
.page-search-bar input:hover::-webkit-input-placeholder::-ms-input-placeholder {
  -webkit-transform: translateX(-70%);
  transform: translateX(-70%);
  opacity: 0;
  color: rgba(0, 0, 0, 0.65) !important;
  background: rgba(255, 255, 255, 0.9) !important;
}

.page-search-bar input:focus {
  color: rgba(0, 0, 0, 0.65) !important;
  background: rgba(255, 255, 255, 0.9) !important;
  text-align: left !important;
}
.page-search-bar input:focus::-webkit-input-placeholder  {
  opacity: 0;
  color: rgba(0, 0, 0, 0.65) !important;
  background: rgba(255, 255, 255, 0.9) !important;
}
.page-search-bar input:focus::-webkit-input-placeholder:-moz-placeholder::-moz-placeholder {
  -webkit-transform: translateX(-70%);
  transform: translateX(-70%);
  opacity: 0;
  color: rgba(0, 0, 0, 0.65) !important;
  background: rgba(255, 255, 255, 0.9) !important;
}
.page-search-bar input:focus::-webkit-input-placeholder:-ms-input-placeholder {
  -webkit-transform: translateX(-70%);
  transform: translateX(-70%);
  opacity: 0;
  color: rgba(0, 0, 0, 0.65) !important;
  background: rgba(255, 255, 255, 0.9) !important;
}
.page-search-bar input:focus::-webkit-input-placeholder::-ms-input-placeholder {
  -webkit-transform: translateX(-70%);
  transform: translateX(-70%);
  opacity: 0;
  color: rgba(0, 0, 0, 0.65) !important;
  background: rgba(255, 255, 255, 0.9) !important;
}

.page-search-bar input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
  -webkit-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
  -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
}
.page-search-bar input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
  -moz-transition: ease-in 0.s;
  transition: ease-in 0.s;
  transform-origin: 0 50%;
}
.page-search-bar input::-moz-placeholder{
  color: rgba(255, 255, 255, 0.8);
  -moz-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
  transform-origin: 0 50%;
}
.page-search-bar input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
  -ms-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
  transform-origin: 0 50%;
}
.page-search-bar input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
  -ms-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
  transform-origin: 0 50%;
}

.search-icons {
  border: none !important;
  line-height: normal !important;
  padding: 0 !important;
  color: rgba(255, 255, 255, 0.9) !important;
}

.page-search-bar .search-bar-dropdown-menu {
  border-left: #1890ff 1px !important;
  border-right: #1890ff 1px !important;
  border-bottom: #1890ff 1px !important;
}
.page-search-bar .rbt-menu {
  z-index: 999 !important;
  margin: 0 !important;
  padding: 0 !important;
  list-style: none !important;
  background: rgba(255, 255, 255, 0.9) !important;
}
.page-search-bar .rbt-menu link {
  width: 99% !important;
  margin-left: 0.5% !important;
  margin-right: 0.5% !important;
}
.page-search-bar .rbt-menu .linkText {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.page-search-bar mark {
  background-color: #FBF1A9 !important;
  color: #357EDD !important;
  font-weight: 800 !important;
  padding: 0 !important; 
}

.search-bar-dropdown-hover:hover {
  color: #000000 !important;
  background-color: #FFFF00 !important;
}



/*** BURGER-MENU CSS DISPLAY SETTINGS  ***/
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 25px;
  right: 36px;
  top: 0px;
}
/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #FFFFFF;
}
/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #000000;
}
/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}
/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}
/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}
/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}
/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}
/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}
/* Individual item */
.bm-item {
  display: inline-block;
}
/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
/*** FIXED & RELATIVE POSITIONING FOR SCREEN FOR USE OF BURGER-MENU DISPLAY ***/
/*** PRIMARILY FOR USE TO CHANGE THE POSITION SETTINGS BETWEEN PAGE SWITCHES ***/
.relative-position {
  position: relative;
}
.fixed-position {
  position: fixed;
}


/*** CUSTOM LINK-HOVER SETTINGS TO SUPERCEED ANTD STANDARD BUTTON SETTINGS ***/
.button-hover:hover {
  color: #000000 !important;
  background: #FFFF00 !important;
}
.settings-button {
  color: #FFFFFF !important;
  border: none !important;
}
.settings-button:hover {
  color: #000000 !important;
}
.cards-hover:hover {
  font-weight: 400;
  color: #000000 !important;
  background: #FFFF00 !important
}
.cards-hover:hover h1 {
  color: #000000 !important;
}
.cards-hover:hover .ant-avatar {
  border-color: #c4c4c4 !important;
}
.cards-hover:hover .aspect-ratio--object {
  opacity: .9 !important;
}
.cards-hover:hover .icon-popup {
  display: inline-block;
}
.cards-new-hover:hover {
  border-color: #1890ff !important;
  color: #1890ff !important;
  font-weight: 500;
}
.cards-tab-hover:hover {
  opacity: .9 !important;
  background: #FFFF00 !important;
}
.cards-tab-hover:hover .flex {
  background: none !important;
  color: #000000 !important;
}
.tag-pill {
  min-width: 40px !important;
}




/*** ALICE-CAROUSEL CSS DISPLAY SETTINGS ***/
.alice-carousel__stage-item, .alice-carousel__stage {
  width: 100% !important;
}
.alice-carousel__dots {
  margin: 0px !important;
}
.alice-carousel__dots-item:hover, .alice-carousel__dots-item.__active {
  background-color: #1890ff !important;
}




.ant-form-explain {
  font-family: avenir next,avenir,sans-serif !important;
  font-weight: 600 !important;
  font-size: .75rem !important;
  padding-left: 10px !important;
  font-style: italic !important;
}

.steps-header {
  min-height: 45px;
  background: rgba(255, 255, 255, 0.9);
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 98;
}
.steps-content {
  margin-top: 30px;
  min-height: 200px;
  text-align: center;
}
.steps-footer {
  margin-top: 24px;
  margin-bottom: 15px;
  min-height: 45px;
  background: rgba(255, 255, 255, 0.9);
  padding-top: 20px;
  padding-bottom: 40px;
  z-index: 98;
}
.steps-nav-anchor-right {
  padding-top: 60px;
}
.steps-content-create {
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
}
.steps-content-payment {
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
}


.Draftail-Toolbar {
  padding: 0px !important;
}
.Draftail-ToolbarButton__label {
  display: block !important;
  height: 1.2em !important;
}
.Draftail-Editor .DraftEditor-root {
  font-size: 14px !important;
}


.ant-tag-checkable {
  border-color: gray !important;
}
.ant-tag-checkable-checked {
  border-color: #1890ff !important;
}
.ant-tag {
  margin-bottom: 6px !important;
}


.card-highlight {
  background: #FFFF00;
  font-weight: 500;
}
.checkbox-highlight {
  color: #000000;
}
.checkbox-nohighlight {
  color: #AAAAAA;
}


.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__input {
  margin: 0;
  padding: 6px 11px;
  box-sizing: border-box;
  list-style: none;
  font-family: inherit;
  -webkit-font-feature-settings: 'tnum', "tnum", "tnum";
          font-feature-settings: 'tnum', "tnum", "tnum";
  font-variant: tabular-nums;
  font-size: 16px;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 40px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  overflow: visible;
}
.react-autosuggest__input:focus {
}
.react-autosuggest__container--open .react-autosuggest__input {
}
.react-autosuggest__suggestions-container {
  display: none;
}
.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 42px;
  width: 100%;
  border: 1px solid #aaa;
  color: #000000;
  background-color: #ffffff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}
.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0px 20px;
}
.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}
.react-autosuggest__suggestion--focused {
  background-color: #0C7EAF;
  color: #fff;
}
.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}
.react-autosuggest__suggestion--focused .highlight {
  color: #120000;
}



.suggestion-textarea {
  line-height: 45px;
}

.highlight {
  color: #357EDD;
  font-weight: 600;
}

.react-autosuggest__suggestion--focused .highlight {
  color: #120000;
}



#newTagsInputField {
  width: 90px !important;
  height: 22px !important;
  padding: 0 !important;
  background: #FFFCEB !important;
}



#form-b-preview-info-alert span.ant-alert-message {
  font-weight: 600 !important;
  text-align: left;
}


header nav div a:hover {
  color: #ffffff !important;
}
header nav div a:focus {
  color: #ffffff !important;
}



#creditcardnumberbox input, #creditcardnumberbox .StripeElement, #creditcardenamebox input, #promocodebox input, #creditcardemailbox input {
  height: 40px !important;
  padding: 10px 15px !important;

  color: #32325d !important;
  background-color: white !important;
  border: 1px solid transparent !important;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1 !important;
  transition: box-shadow 150ms ease !important;
}

input#creditcardname, input#promocode, input#creditcardemail {
  padding-left: 45px !important;
}

#creditcardenamebox .ant-input-prefix, #promocodebox .ant-input-prefix, #creditcardemailbox .ant-input-prefix {
  margin-left: 8px !important;
}

#totalpayabledisplay {
  background-color: white !important;
  border: 1px solid transparent !important;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1 !important;
  transition: box-shadow 150ms ease !important;
}





/*** SPECIAL CODE REQUEST FOR RESIZING ONLY AT PHONE-SPECIFIC SIZES ***/
@media only screen and (min-width: 320px) and (max-width: 480px) {
  #main-anchor-left {
    display: none;
  }
  #newjob-anchor-right {
    display: none;
  }
  
  #sidepanel-logo {
    display: none;
  }
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

